.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'League Spartan';
  src: url('./Fonts/LeagueSpartan-Regular.ttf') format('truetype'); /* Adjust path if necessary */
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Press Start 2P';
  src: url('./Fonts/PressStart2P-Regular.ttf') format('truetype'); /* Adjust path if necessary */
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* square buttons */
.rec.rec-arrow {
    border-radius: 50%;
    background-color: #5f89af;
    color: white;
    margin-left: 20px; /* Adjust the value as needed */
    margin-right: 20px;

}

.rec.rec-arrow:disabled {
    border-radius: 50%;
    background-color: gray;
    color: white;
    margin-left: 20px; /* Adjust the value as needed */
    margin-right: 20px;
}

/* hide disabled buttons */

/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 245, 197, 0.5); /* Adjust the color and opacity as desired */
  z-index: 1;
}

.image-container {
  position: relative;
  display: inline-block;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.font-tech {
  font-family: 'Orbitron', sans-serif;
}

.grid-fit-text {
  grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header{
  height: 63px;
  background: rgb(243,181,79);
  background: linear-gradient(180deg, rgba(11, 11, 96) 33.3%, rgba(100,135,173) 33.3%, rgba(113,186,219) 66.6%, rgba(154,221,230) 66.66%, rgba(154,221,230) 100%);
}

@media (min-width:640px) {
  .header{
    height: 100px;
    background: rgb(243,181,79);
    background: linear-gradient(180deg, rgba(11, 11, 96) 33.3%, rgba(100,135,173) 33.3%, rgba(113,186,219) 66.6%, rgba(154,221,230) 66.66%, rgba(154,221,230) 100%);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
}

.bounce {
  animation: bounce 1s infinite;
}

.background-image {
    background-image: url('./img/BodyShop.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; /* You can also use 'contain' here if you want the image to be fully visible */
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-50px); /* Change the negative value to move the element from top to bottom */
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideInFromTop {
  animation: 1s ease-out 0s 1 slideInFromTop;
}
.slideInFromTopTwo {
  animation: 2s ease-out 0s 1 slideInFromTop;
}
.slideInFromTopThree {
  animation: 3s ease-out 0s 1 slideInFromTop;
}
.slideInFromTopFour {
  animation: 4s ease-out 0s 1 slideInFromTop;
}

@keyframes slideIn {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideIn {
  animation: 1s ease-out 0s 1 slideIn;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(50px); /* Change the negative value to move the element from top to bottom */
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slideInNav {
  animation: 4s ease-out 0s 1 slideInFromTop;
}

.caret {
  animation: blink-caret 1s step-end infinite;
}

@keyframes blink-caret {
  from, to { border-right-color: transparent; }
  50% { border-right-color: #000; } /* Change the color here to match your text color */
}

@keyframes slideInAboutTop {
  0% {
    transform: translateY(-100px); /* Change the negative value to move the element from top to bottom */
  }
  100% {
    transform: translateY(0);
  }
}

.slideInAboutTop {
  animation: 0.5s ease-out 0s 1 slideInAboutTop;
}

@keyframes slideInAboutBottom {
  0% {
    transform: translateY(100px); /* Change the negative value to move the element from top to bottom */
  }
  100% {
    transform: translateY(0);
  }
}

.slideInAboutBottom {
  animation: 0.25s ease-out 0s 1 slideInAboutBottom;
}

@keyframes slideOutAboutTop {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100px);
  }
}

@keyframes slideOutAboutBottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}

.slideOutAboutTop {
  animation: 0.5s ease-out 0s 1 slideOutAboutTop forwards;
}

.slideOutAboutBottom {
  animation: 0.5s ease-out 0s 1 slideOutAboutBottom forwards;
}
